import React, { useEffect, useState } from "react";
import crmService from "../infrastructure/crm-service";
import Header from "../../../components/NavBar";
import { IconButton, Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import { BiLogOut } from "react-icons/bi"
import { TbRefresh } from "react-icons/tb"
import { useNavigate } from "react-router-dom";
import crmRefreshDBService from "../infrastructure/crm-refreshdb-service";
import PowerBiReport from "../components/PowerBiReport";
import { InfoReport, PowerBIReports } from "../entities/powerBIReports";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { Roles } from "../entities/roles";



export default function Reportes() {

  const navigate = useNavigate()
  const [loadingRefresh, setLoadingRefresh] = useState({ loading: false, error: false })
  const [loadingReports, setLoadingReports] = useState({ loading: true, error: false })
  const [reports, setReports] = useState<InfoReport[]>([])
  const [defaultReport,setDefaultReport] = useState("")

  useEffect(() => {
    const reports = crmService.GetReportsByRol()
    setDefaultReport(reports[0].name)
    

    

    const getReports = async () => {
      for (const report of reports) {
        const tokens = await crmService.GetGeneralInfoReport({datasets:report.datasets,reports:[{id:report.id}],needsIdentities:report.needsIdentities})
        report.accessToken = tokens.token
        report.embedUrl = tokens.EmbedUrl
        var tokenDecode = jwtDecode<JwtPayload>(report.accessToken!)
        report.exp = tokenDecode.exp
      }
    }

    getReports().then(() => {
      setReports(reports)
      setLoadingReports({ loading: false, error: false })
    })
  }, [])

  const getTokenInfo = async (v: InfoReport) => {
    crmService.GetGeneralInfoReport({reports:[{id:v.id}],datasets:v.datasets,needsIdentities:v.needsIdentities}).then(({ token, EmbedUrl, error }) => {

      v.accessToken = token
      v.embedUrl = EmbedUrl
      var tokenDecode = jwtDecode<JwtPayload>(v.accessToken!)
      v.exp = tokenDecode.exp
    }).catch((e) => {
      console.log(e)
      window.location.href = "/login"
    })
  }


  const handleRefresh = async (e: any) => {
    const resp = await crmRefreshDBService.RefreshDB()
    console.log(resp)
    if (resp.status == 200) {


      const idRefresh = await crmService.ActualizarBasedeDatos(PowerBIReports.GroupPowerBIEmbed, PowerBIReports.BaseGeneral)

      console.log(idRefresh)
      setLoadingRefresh({ error: false, loading: true })

      const codeInterval = setInterval(async () => {

        crmService.ObtenerEstatusRefesh(PowerBIReports.GroupPowerBIEmbed, PowerBIReports.BaseGeneral, idRefresh).then((resp) => {
          console.log(resp)
          if (resp.status === "Completed") {
            clearInterval(codeInterval)
            window.location.reload();
          }
          if (resp.status === "Failed") {
            setLoadingRefresh({ error: true, loading: false })
            clearInterval(codeInterval)
          }

        })
      }, 3000);

    } else {

      alert("Algo fallo al refrescar la base de datos")

    }
  }

  function verifyTokenExpiration(e: any, v: InfoReport): void {



    if (!v.exp) {
      return
    }
    console.log(Date.now(), v.exp * 1000)
    if (Date.now() > v.exp * 1000) {
      console.log("Actualizar el token")
      getTokenInfo(v)
    }

  }

  return (
    <div className="w-full h-auto bg-purple relative">
      <Header fixed />
      <button className="absolute top-10 left-10" onClick={() => {
        localStorage.clear();
        navigate("/login", {
          replace: true
        })
      }}>
        <IconButton children={<BiLogOut size={32} />} />
      </button >
      <button className="absolute top-10 right-10 flex items-center" onClick={handleRefresh}>
        {loadingRefresh.error && <p className="text-white ">Algo ha fallado al intentar actualizar</p>}
        <IconButton className={loadingRefresh.loading ? "animate-spin" : ""} children={<TbRefresh size={32} />} />
      </button>
      {loadingReports.loading && <div className="w-screen h-screen flex items-center justify-center bg-purple"><IconButton className="animate-spin" children={<TbRefresh size={52} />} /></div>}
      {!loadingReports.loading && <Tabs classname="flex" value={defaultReport} >
        <TabsHeader className="m-10">
          {reports.map((v) => (
            <Tab key={v.id} value={v.name} onClick={(e) => verifyTokenExpiration(e, v)}>
              {v.name}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody >
          {reports.map((v) => {
            console.log(v)
            return <TabPanel key={v.id} value={v.name} >
              {v.accessToken && v.embedUrl && <PowerBiReport pageName={v.id == PowerBIReports.InformeDistribuidor? "ReportSection8e63e2cd857c63b82566": v.id == PowerBIReports.Tiempos? "ReportSection55bda1002423872a5bb6": null }  reportid={v.id} token={v.accessToken} embedUrl={v.embedUrl} />}
            </TabPanel>
          }
          )}
        </TabsBody>
      </Tabs>}
    </div >
  );
}
