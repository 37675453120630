
import React, { useEffect } from "react";
import Header from "../components/NavBar";
import Footer from "../components/Footer";

export default function Politicas(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div>
            <Header/>
            <div className="p-5 md:p-20 md:pb-0" >
                <hr/>
                <div className="text-xl md:p-10 font-bold pb-0" >
                    POLITICAS DE TRATAMIENTO DE DATOS PERSONALES
                </div>

                <div  className="ml-10 mt-5">
                    <strong>FINANCIAMOS SU MAQUINA S.A.S (“FinMaq”)</strong> recopila y trata datos personales a
                    través del <strong>acceso, navegación y uso del sitio web <a href="https://www.finmaq.com.co/"><u>www.finmaq.com.co</u></a>, así como a través de
                    la aplicación móvil Finmaq Comerciales y del diligenciamiento del Formulario de Vinculación
                    Comercial</strong> para acceder a los productos que ofrece en desarrollo de su actividad social.
                 </div>

                <div className="ml-10 mt-5">
                    <strong> FinMaq</strong> pone a disposición de los diferentes grupos de interés las presentes Políticas en
                    virtud de las cuales se proporciona de manera transparente y sencilla toda la información
                    relativa al tratamiento que realizará de sus datos personales, las finalidades para las cuales
                    los tratamos y los derechos que pueden ejercer los titulares.
                </div>
                
                <div className="ml-10 mt-5">Los datos personales obtenidos en virtud de las relaciones comerciales y/o contractuales que
                    se soliciten o celebren con <strong> FinMaq,</strong> serán tratados conforme a los principios y deberes
                    definidos en la Ley 1581 de 2012 y demás normas que traten y regulen esta materia.
                </div>


                <div className="ml-10 mt-5">
                    Para todos los fines pertinentes, <strong> FinMaq</strong> es la RESPONSABLE del tratamiento de los datos
                    personales, con domicilio principal en la Carrera 5 #117-13 de la ciudad de Bogotá; Celular
                    <b>3017106346, página corporativa www.finmaq.com.co y correo electrónico : datos
                    personales@fsm.com.co.</b>
                </div>

                <div className="text-xl md:p-10 font-bold pb-0" >
                    DESTINATARIOS.
                </div>

                <div className="ml-10 mt-5">
                    Estas políticas se aplicará a todas las bases de datos tanto físicas como digitales, que
                    contengan datos personales y que sean objeto de Tratamiento por parte de <strong>FinMaq,</strong> las
                    cuales están dirigidas a todas las personas naturales o jurídicas con las cuales pretenda
                    establecer o tenga relaciones comerciales y/o contractuales.
                </div>

                <div className="text-xl md:p-10 font-bold pb-0" >
                    PRINCIPIOS RECTORES.
                </div>

                <div className="ml-10 mt-5">
                    En el desarrollo, interpretación y aplicación de la ley, regulaciones, y normatividad vigente,
                    se aplicarán, de manera armónica e integral, los siguientes principios:
                </div>

                <div className="ml-16 mt-5">
                    <b>a)</b> Principio de Legalidad en Materia de Tratamiento de Datos: El Tratamiento es una
                    actividad reglada que debe sujetarse a lo establecido en la Ley 1581 del 17 de octubre de
                    2012, decretos reglamentarios y demás disposiciones que la desarrollen.
                </div>    

                <div className="ml-16 mt-5">
                    <b>b)</b>Principio de Finalidad: El Tratamiento debe obedecer a una finalidad legítima de
                    acuerdo con la Constitución y la Ley, la cual debe ser informada al Titular
                </div>      

                <div className="ml-16 mt-5">
                    <b>c)</b> Principio de Libertad: El Tratamiento sólo puede ejercerse con el consentimiento,
                    previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o
                    divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el
                    consentimiento.
                </div>

                <div className="ml-16 mt-5">
                    <b>d)</b> Principio de Veracidad o Calidad: La información sujeta a Tratamiento debe ser
                    veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el
                    Tratamiento de Datos parciales, incompletos, fraccionados o que induzcan a error.
                </div>

                <div className="ml-16 mt-5">
                    <b>e)</b> Principio de Transparencia: En el Tratamiento debe garantizarse el derecho del
                    Titular a obtener en cualquier momento y sin restricciones, información acerca de la
                    existencia de datos que le conciernan.
                </div>

                <div className="ml-16 mt-5">
                    <b>f)</b> Principio de Acceso y Circulación Restringida: El Tratamiento se sujeta a los límites
                    que se derivan de la naturaleza de los datos personales, de las disposiciones de la ley y la
                    Constitución. En este sentido, el Tratamiento sólo podrá hacerse por personas autorizadas
                    por el Titular y/o por las personas previstas en la ley.
                </div>

                <div className="ml-16 mt-5">
                    Los datos personales, salvo la información pública, no podrán estar disponibles en Internet
                    u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente
                    controlable para brindar un conocimiento restringido sólo a los Titulares o terceros
                    autorizados.
                </div>

                <div className="ml-16 mt-5">
                    <b>g)</b> Principio de Seguridad: La información sujeta a Tratamiento, se deberá manejar
                    con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar
                    seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no
                    autorizado o fraudulento.
                </div>

                <div className="ml-16 mt-5">
                    <b>h)</b> Principio de Confidencialidad: Todos los empleados, contratistas, proveedores y
                    clientes potenciales y clientes que intervengan en el Tratamiento de Datos Personales que
                    no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la
                    información, inclusive después de finalizada su relación con alguna de las labores que
                    comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de datos
                    personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley y
                    en los términos de la misma. <strong> FinMaq</strong> se compromete a tratar los datos personales de los
                    titulares tal y como lo define el literal g) del artículo 3 de la Ley 1581 de 2012 de forma
                    absolutamente confidencial haciendo uso de estos, exclusivamente, para las finalidades
                    indicadas en el apartado anterior, siempre que el titular no se haya opuesto a dicho
                    tratamiento.
                </div>

                <div className="ml-16 mt-5">
                    <b>i)</b> Principio de temporalidad: Los datos personales se conservarán únicamente
                    por el tiempo razonable y necesario para cumplir las finalidades que justificaron el
                    tratamiento, atendiendo a las disposiciones aplicables a la materia de que se trate y a los
                    aspectos administrativos, contables, fiscales, jurídicos e históricos de la información. Los
                    datos serán conservados cuando ello sea necesario para el cumplimiento de una obligación
                    legal o contractual. Una vez cumplida la finalidad del tratamiento y los términos establecidos
                    anteriormente, se procederá a la supresión de los datos.
                </div>

                <div className="ml-16 mt-5">
                    <b>j)</b> Interpretación integral de los derechos constitucionales: Los derechos se
                    interpretarán en armonía y en un plano de equilibrio con el derecho a la información previsto
                    en el artículo 20 de la Constitución y con los derechos constitucionales aplicables.
                <div className="ml-16 mt-5">

                </div>
                    <b>k)</b> Principio de Necesidad: Los datos personales tratados deben ser los estrictamente
                    necesarios para el cumplimiento de las finalidades perseguidas con la base de datos.
                </div>

                
                <div className="text-xl md:p-10 font-bold pb-0" >
                    DATOS SENSIBLES
                </div>

                <div className="ml-10 mt-5">
                    Se entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso
                    indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o
                    étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a
                    sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de
                    cualquier partido político o que garanticen los derechos y garantías de partidos políticos de
                    oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos.
                </div>

                <div className="ml-10 mt-5">
                    Autorización especial de datos personales sensibles:
                </div>

                <div className="ml-10 mt-5">
                    <strong>FinMAq</strong> informará que en virtud de la ley 1581 del 2012 y normas reglamentarias estos no
                    están obligados a otorgar la autorización para el tratamiento de datos sensibles.
                </div>

                <div className="ml-10 mt-5">
                    En caso de tratamiento de datos relativos a la salud, <strong> FinMaq implementará las medidas </strong>
                    necesarias para proteger la confidencialidad de la información. Los datos sensibles
                    biométricos tratados tienen como finalidad la identificación de las personas, la seguridad, el
                    cumplimiento de obligaciones legales y la adecuada prestación de los productos.
                </div>


                <div className="text-xl md:p-10 font-bold pb-0" >
                    TRATAMIENTO Y FINALIDADES.
                </div>

                <div className="ml-10 mt-5">
                    De acuerdo con lo establecido en la Ley 1581 de 2012 y de conformidad con las
                    autorizaciones impartidas por los titulares de la información, <b>FinMaq</b> realizará operaciones o
                    conjunto de operaciones que incluyen recolección de datos, su almacenamiento, uso,
                    circulación y/o supresión. Este Tratamiento de datos se realizará exclusivamente para las
                    finalidades autorizadas y previstas en la presente Política y en las autorizaciones específicas
                    otorgadas por parte del titular.

                </div>

                <div className="ml-10 mt-5">
                    Así mismo y en ejecución del objeto social de FinMaq, los datos personales serán tratados
                    de acuerdo con el grupo de interés y en proporción a la finalidad o finalidades que tenga
                    cada tratamiento, como se describe a continuación:
                </div>


                <div className="ml-10 mt-5  font-semibold">
                    1. Clientes potenciales y clientes:
                </div>

                <div className="ml-16 mt-5">
                    - Verificación de identidad.
                </div>

                <div className="ml-16">
                    - Gestionar y ofrecer nuestros productos.
                </div>

                <div className="ml-16">
                    - Fines comerciales y de fidelización;
                </div>

                <div className="ml-16">
                    - Administración y evaluación de Riesgo
                </div>

                <div className="ml-16">
                    - Para el cumplimiento de obligaciones contractuales contraídas, para lo cual, entre
                    otras, podrá recibir llamadas recordatorias o correspondencia escrita o electrónica de la
                    fecha de vencimiento y estado de sus obligaciones. Adicionalmente, FinMaq podrá
                    monitorear, grabar y conservar copias de todas las comunicaciones suministradas y
                    recibidas incluyendo, pero sin limitarse a correos electrónicos, llamadas telefónicas y
                    correspondencia postal, todo lo cual será aceptado como prueba válida en un
                    procedimiento judicial.
                </div>

                <div className="ml-16">
                    - En general, para gestionar la información necesaria para el cumplimiento de las
                    obligaciones tributarias, contractuales, comerciales y de registros comerciales,
                    corporativos y contables.

                </div>
                <div className="ml-10 mt-5 font-semibold">
                    2. Garantes:
                </div>

                <div className="ml-16 mt-5">
                    - Verificación de identidad.
                </div>

                <div className="ml-16 ">
                    - Administración y evaluación de Riesgo.
                </div>

                <div className="ml-16 ">
                    - Para el cumplimiento de obligaciones contractuales contraídas, para lo cual, entre
                    otras, podrá recibir llamadas recordatorias o correspondencia escrita o electrónica de la
                    fecha de vencimiento y estado de sus obligaciones. Adicionalmente, FinMaq S.A.S.
                    podrá monitorear, grabar y conservar copias de todas las comunicaciones suministradas
                    y recibidas incluyendo, pero sin limitarse a correos electrónicos.
                </div>

                <div className="ml-16 ">
                    - Llamadas telefónicas y correspondencia postal, todo lo cual será aceptado como
                    prueba válida en un procedimiento judicial.
                </div>

                <div className="ml-16 ">
                    - En general, para gestionar la información necesaria para el cumplimiento de las
                    obligaciones tributarias, contractuales, comerciales y de registros comerciales,
                    corporativos y contables.
                </div>

                <div className="ml-10 mt-5 font-semibold">
                    3. Empleados:
                </div>

                <div className="ml-16 mt-5">
                    - Realizar las actividades necesarias para dar cumplimiento a las obligaciones legales en
                    relación con los empleados y ex empleados de FinMaq.
                </div>

                <div className="ml-16">
                    - Controlar el cumplimiento de requisitos relacionados con el Sistema General de Seguridad
                    Social.
                </div>

                <div className="ml-16">
                    - Publicar el directorio corporativo con la finalidad de contacto de los empleados.
                </div>

                <div className="ml-16">
                    - Gestionar el proceso Contable de <strong> FinMaq.</strong>
                </div>

                <div className="ml-10 mt-5 font-semibold">
                    4. Proveedores/contratistas:
                </div>

                <div className="ml-16  mt-5">
                    - Para todos los fines relacionados con el objeto de los procesos de selección,
                    contractuales o relacionados con éstos.
                </div>

                <div className="ml-16 ">
                    - Realizar todos los trámites internos y el cumplimiento de obligaciones contables,
                    tributarias y de ley.
                </div>

                <div className="ml-16 ">
                    - Gestionar la emisión de certificados de ingresos y retenciones (personas naturales
                    y jurídicas) y relaciones de pagos.
                </div>

                <div className="ml-16 ">
                    - Gestionar el proceso Contable.
                </div>

                <div className="ml-16 ">
                    - Realizar todas las actividades necesarias para el cumplimiento de las diferentes
                    etapas contractuales en las relaciones con proveedores y contratistas.
                </div>

                <div className="ml-16 ">
                    - Expedir las certificaciones contractuales solicitadas por los contratistas.
                </div>

                <div className="ml-16 ">
                    - Mantener un archivo digital que permita contar con la información correspondiente
                    a cada contrato.
                </div>

                <div className="ml-16 ">
                    - Las demás finalidades que se determinen en procesos de obtención de Datos
                    Personales para su tratamiento, y en todo caso de acuerdo con la Ley y en el
                    marco del objeto social de FinMaq.
                </div>
                
                <div className="text-xl md:p-10 font-bold pb-0" >
                    DERECHOS PARA EL TRATAMIENTO DE DATOS.
                </div>

                <div className="ml-10 mt-5">
                    En el Tratamiento de Datos Personales por parte de FinMaq se respetarán en todo
                    momento los derechos de los titulares de Datos Personales que son:    
                </div>

                <div className="ml-16 mt-5">    
                    - Conocer, actualizar y rectificar los Datos del Tratamiento de Datos.
                </div>

                <div className="ml-16">
                    - Solicitar prueba de la autorización otorgada, o cualquier otra que suscriba el
                    Titular de los Datos Personales para el efecto, salvo cuando expresamente se
                    exceptúe como requisito para el Tratamiento de Datos de conformidad con la ley.    
                </div>

                <div className="ml-16">    
                    - Ser informado por FinMaq, previa solicitud, respecto del uso que se le ha dado a
                    los datos.
                </div>

                <div className="ml-16">
                    - Presentar ante la Autoridad Competente quejas por infracciones a lo dispuesto en
                    la ley y las demás normas que la modifiquen, sustituyan o adicionen.    
                </div>

                <div className="ml-16">
                    - Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento
                    no se respeten los principios, derechos y garantías constitucionales y legales. La
                    revocatoria y/o supresión procederá cuando la Autoridad Competente haya
                    determinado que, en el Tratamiento FinMaq, ha incurrido en conductas
                    contrarias a la ley y a la Constitución. La revocatoria procederá siempre y cuando
                    no exista la obligación legal o contractual de conservar el dato personal.
                </div>
                        
                <div className="text-xl md:p-10 font-bold pb-0">
                    AUTORIZACIÓN DEL TITULAR.
                </div>

                <div className="ml-10 mt-5">
                    Sin perjuicio de las excepciones previstas en la ley, en el Tratamiento se requiere la
                    autorización previa e informada del Titular, la cual deberá ser obtenida por cualquier medio
                    que pueda ser objeto de consulta posterior. Se entenderá que la autorización cumple con
                    estos requisitos cuando se manifieste <b>(i)</b> por escrito,  <b>(ii)</b> de forma oral o <b>(iii)</b> mediante
                    conductas inequívocas del titular que permitan concluir de forma razonable que otorgó la
                    autorización, como cuando, por ejemplo, se remite a FinMaq una hoja de vida para
                    participar en procesos de selección o cuando se ingresa a las <b>oficina propias o de los aliados
                    autorizados</b> a sabiendas de la existencia de sistemas de videovigilancia.
                </div>

                <div className="ml-10 mt-5">
                    Casos en que no es necesaria la autorización:
                </div>

                <div className="ml-10 mt-5">
                    La autorización del Titular no será necesaria cuando se trate de:
                </div>
                
                <div className="ml-16">
                    - Información requerida por <b> FinMaq</b>, en ejercicio de sus funciones legales o por orden
                    judicial.
                </div>

                <div className="ml-16">
                    - Datos de naturaleza pública.
                </div>

                <div className="ml-16">
                    - Casos de urgencia médica o sanitaria.
                </div>
                
                <div className="ml-16">
                    - Tratamiento de información autorizado por la ley para fines históricos, estadísticos o
                    científicos.
                </div>

                <div className="ml-16">
                    - Datos relacionados con el Registro Civil de las Personas.
                </div>

                <div className="ml-10 mt-5">
                    Quien acceda a los datos personales sin que medie autorización previa deberá en todo caso
                    cumplir con las disposiciones contenidas en la Ley 1581 de 2012 y demás normas
                    concordantes y vigentes.
                </div>
                
                
                <div className="text-xl md:p-10 font-bold pb-0">
                    SUMINISTRO DE LA INFORMACIÓN.
                </div>

                <div className="ml-10 mt-5">
                    La información solicitada por los Titulares de información personal será suministrada
                    principalmente por medios electrónicos, o por cualquier otro solo si así lo requiere el Titular.
                    La información suministrada por <b>FinMaq será entregada sin barreras técnicas</b> que impidan
                    su acceso; su contenido será de fácil lectura, acceso y tendrá que corresponder en un todo a
                    aquella que repose en la base de datos.
                </div>

                <div className="text-xl md:p-10 font-bold pb-0">
                    DEBER DE INFORMAR AL TITULAR
                </div>

                <div className="ml-10 mt-5">
                    <b>FinMaq</b>, al momento de solicitar al Titular la autorización, deberá informarle de manera
                    clara y expresa lo siguiente:
                </div>
                
                <div className="ml-16 mt-5">
                    - El Tratamiento al cual serán sometidos sus datos personales y la finalidad del mismo.
                </div>

                <div className="ml-16">
                    - El carácter facultativo de la respuesta a las preguntas que le sean hechas, cuando estas
                    versen sobre datos sensibles o sobre los datos de las niñas, niños y adolescentes.
                </div>

                <div className="ml-16">
                    - Los derechos que le asisten como Titular.
                </div>

                <div className="ml-16">
                    - La identificación, dirección física o electrónica y teléfono del responsable del Tratamiento.
                    <b>     FinMaq</b> como responsable del Tratamiento, <b>deberá conservar prueba del cumplimiento</b> de
                    lo previsto en el presente numeral y, cuando el Titular lo solicite, entregarle copia de esta.
                </div>
                
                <div className="text-xl md:p-10 font-bold pb-0">
                    PERSONAS A QUIENES SE LES PUEDE SUMINISTRAR LA INFORMACIÓN.
                </div>

                <div className="ml-10 mt-5">
                    La información que reúna las condiciones establecidas en la ley podrá ser suministrada a las
                    siguientes personas:
                </div>

                <div className="ml-16 mt-5">
                    - A los Titulares, sus causahabientes o sus representantes legales.
                </div>

                <div className="ml-16 ">
                    - A las Entidades públicas o administrativas en ejercicio de sus funciones legales o por
                    orden judicial.
                </div>

                <div className="ml-16 ">
                    - A los terceros autorizados por el Titular o por la ley.
                </div>

                <div className="text-xl md:p-10 font-bold pb-0">
                    DEBERES DE FINMAQ COMO RESPONSABLES Y ENCARGADO DEL TRATAMIENTO.
                </div>

                <div className="ml-16 mt-5">
                    - Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas
                    data.
                </div>

                <div className="ml-16 ">
                    - Solicitar y conservar, en las condiciones previstas en la ley, copia de la respectiva
                    autorización otorgada por el Titular.
                </div>

                <div className="ml-16 ">
                    - Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que
                    le asisten por virtud de la autorización otorgada.
                </div>

                <div className="ml-16 ">
                    - Conservar la información bajo las condiciones de seguridad necesarias para impedir su
                    adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                </div>

                <div className="ml-16 ">
                    - Garantizar que la información que se suministre a terceros legitimados sea veraz,
                    completa, exacta, actualizada, comprobable y comprensible.
                </div>

                <div className="ml-16 ">
                    - Adoptar las demás medidas necesarias para que la información suministrada a este se
                    mantenga actualizada.
                </div>

                <div className="ml-16 ">
                    - Rectificar la información cuando sea incorrecta.
                </div>

                <div className="ml-16 ">
                    - Tramitar las consultas y reclamos formulados en los términos señalados en la ley.
                </div>

                <div className="ml-16 ">
                    <b>- Adoptar procedimientos específicos para garantizar el adecuado cumplimiento de la ley y
                    en especial, para la atención de consultas y reclamos.</b>
                </div>

                <div className="ml-16 ">
                    - Informar a solicitud del Titular sobre el uso de sus datos.
                </div>

                <div className="ml-16 ">
                    - Informar a la autoridad de protección de datos cuando se presenten violaciones a los
                    códigos de seguridad y existan riesgos en la administración de la información de los
                    Titulares.
                </div>

                
                <div className="text-xl md:p-10 font-bold pb-0">
                    ACCIONES GENERALES PARA LA PROTECCIÓN DE DATOS PERSONALES.
                </div>

                <div className="ml-10 mt-5">
                    A continuación, se establecen los lineamientos generales aplicados por <b> FinMaq</b> con el fin de
                    cumplir con sus obligaciones en cumplimiento de los principios para la administración de
                    datos personales.
                </div>

                <div className="ml-16 mt-5">
                    <b>a)</b> Tratamiento de la información:
                </div>

                <div className="ml-16 mt-5">
                    Todos los administradores y empleados de <b> FinMaq,</b> al realizar las actividades propias de su
                    cargo, asumirán las responsabilidades y las obligaciones que se tienen en el manejo
                    adecuado de la información personal, desde su recolección, almacenamiento, uso,
                    circulación y hasta su disposición final.    
                </div>

                <div className="ml-16 mt-5">
                    <b>b)</b> Uso de la información:    
                </div>

                <div className="ml-16 mt-5">
                    La información de carácter personal contenida en las bases de datos debe ser utilizada y
                    tratada de acuerdo con las finalidades descritas en estas políticas.    
                </div>

                <div className="ml-16 mt-5">
                    En caso de que algún área identifique nuevos usos diferentes a los descritos en la presente
                    política de tratamiento de datos personales, deberá informar al representante legal de
                    <b>FinMaq</b> , quien evaluará y gestionará la novedad.    
                </div>

                <div className="ml-16 mt-5">
                    Igualmente, se deben tomar en consideración los siguientes supuestos:    
                </div>

                <div className="ml-28 mt-5">
                    - En caso de que un área diferente de la que recolectó inicialmente el dato personal
                    requiera utilizar los datos personales que se han obtenido, ello se podrá hacer siempre que
                    se trate de un uso previsible por el tipo de producto ofrecido por <b> FinMaq</b> y para una
                    finalidad contemplada dentro de la presente Política de Tratamiento de Datos Personales.    
                </div>

                <div className="ml-28 mt-5">
                    - En caso de que un encargado haya facilitado datos personales o bases de datos a algún
                    área para un fin determinado, el área que solicitó los datos personales no debe utilizar dicha
                    información para una finalidad diferente de la relacionada en la Política de Tratamiento de
                    Datos Personales; al finalizar la actividad, es deber del área que solicitó la información,
                    eliminar la base de datos o los datos personales utilizados evitando el riesgo de
                    desactualización de información o casos en los cuales durante ese tiempo un titular haya
                    presentado algún reclamo.    
                </div>

                <div className="ml-28 mt-5">
                    - Los empleados no podrán tomar decisiones que tengan un impacto significativo en la
                    información personal, o que tengan implicaciones legales, con base exclusivamente en la
                    información que arroja el sistema de información, por lo que deberán validar la información
                    a través de otros instrumentos físicos o de manera manual, y, si es necesario, de manera
                    directa por parte del titular del dato, en los casos en que así sea necesario.    
                </div>

                <div className="ml-28 mt-5">
                    - Cualquier uso de la información diferente a lo atrás establecido, será previamente
                    consultado con el Representante Legal.    
                </div>

                <div className="ml-16 mt-5">
                    <b>c)</b> Almacenamiento de información:    
                </div>

                <div className="ml-16 mt-5">
                    El almacenamiento de la información digital y física <b>se realiza en medios o ambientes que
                    cuentan con adecuados controles para la protección de los datos.</b>
                </div>

                <div className="ml-16 mt-5">
                    <b>d)</b> Destrucción:    
                </div>

                <div className="ml-16 mt-5">
                    La destrucción de medios físicos y electrónicos se realiza a través de mecanismos que no
                    permiten su reconstrucción. Se realiza únicamente en los casos en que no constituya el
                    desconocimiento de norma legal alguna, dejando siempre la respectiva trazabilidad de la
                    acción.    
                </div>

                <div className="ml-16 mt-5">
                    La destrucción comprende información contenida en poder de terceros como en las oficinas.    
                </div>

                <div className="text-xl md:p-10 font-bold pb-0">    
                    PROCEDIMIENTO DE GESTIÓN DE INCIDENTES CON DATOS PERSONALES.
                </div>

                <div className="ml-10 mt-5">
                    Se entiende por incidencia cualquier anomalía que afecte o pudiera afectar la seguridad de
                    las bases de datos o información contenida en las mismas.    
                </div>

                <div className="ml-10 mt-5">
                    En caso de conocer alguna incidencia ocurrida, el empleado debe comunicarla al
                    Representante Legal quien adoptará las medidas oportunas frente al incidente reportado. El
                    Representante Legal informará de la incidencia al <b>Comité de Riesgos.</b>    
                </div>

                <div className="ml-10 mt-5">
                    Las incidencias pueden afectar tanto a bases de datos digitales como físicas y generarán las
                    siguientes actividades:    
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    a. Notificación de Incidentes:    
                </div>

                <div className="ml-16 mt-5">
                    Cuando se presuma que un incidente pueda afectar o haber afectado a bases de datos con
                    información personal datos personales se deberá informar al Representante Legal quién
                    gestionará su reporte en el <b>registro que se lleve para el efecto.</b>
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    b. Gestión de Incidentes:    
                </div>

                <div className="ml-16 mt-5">
                    Es responsabilidad de cada empleado, contratista, consultor o tercero, reportar de manera
                    oportuna cualquier evento sospechoso, debilidad o violación de políticas que pueden afectar
                    la confidencialidad, integridad y disponibilidad de los activos e información personal de
                    <b> FinMaq.</b>    
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    c. Identificación:    
                </div>

                <div className="ml-16 mt-5">
                    Todos los eventos sospechosos o anormales, tales como aquellos en los que se observe el
                    potencial de pérdida de reserva o confidencialidad de la información, deben ser evaluados
                    para determinar si son o no, un incidente y deben ser reportados al nivel adecuado en la
                    administración de <b> FinMaq.</b>    
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    d. Reporte:    
                </div>

                <div className="ml-16 mt-5">
                    Todos los incidentes y eventos sospechosos deben ser reportados tan pronto como sea
                    posible a través del correo <b> datos@fsm,.com.co</b>    
                </div>
                <div className="ml-16 mt-5">
                    Si la información sensible o confidencial es perdida, divulgada a personal no autorizado o se
                    sospecha de alguno de estos eventos, el Representante Legal, debe ser notificado de forma
                    inmediata.
                </div>

                <div className="ml-16 mt-5">
                    Los empleados deben reportar a su jefe directo y/o al Representante Legal cualquier daño o
                    pérdida de computadores o cualquiera otro dispositivo, cuando estos contengan datos
                    personales en poder de <b> FinMaq.</b>    
                </div>

                <div className="ml-16 mt-5">
                    A menos que exista una solicitud de la autoridad competente debidamente razonada y
                    justificada, ningún empleado debe divulgar información sobre sistemas de cómputo, y redes
                    que hayan sido afectadas por un delito informático o abuso de sistema. Para la entrega de
                    información o datos en virtud de orden de autoridad, los asesores legales de <b> FinMaq</b>
                    deberán intervenir con el fin de prestar el asesoramiento adecuado.    
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    e. Contención, Investigación y Diagnóstico:    
                </div>

                <div className="ml-16 mt-5">
                    El Representante Legal debe garantizar que se tomen acciones para investigar y diagnosticar
                    las causas que generaron el incidente, así como también debe garantizar que todo el
                    proceso de gestión del incidente sea debidamente documentado, apoyado con el área de
                    Tecnologías e Informática.    
                </div>

                <div className="ml-16 mt-5">
                    En caso de que se identifique un delito informático, el Representante Legal reportará tal
                    información a las autoridades de investigaciones judiciales respectivas.
                </div>

                <div className="ml-16 mt-5">
                    Durante los procesos de investigación se deberá garantizar la “Cadena de Custodia” con el
                    fin de preservarla en caso de requerirse establecer una acción legal.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    f. Solución:    
                </div>

                <div className="ml-16 mt-5">
                    <b>El área de Tecnologías e Informática,</b> así como cualquier área comprometida y los
                    directamente responsables de la gestión de datos personales, deben prevenir que el
                    incidente de seguridad se vuelva a presentar, corrigiendo todas las vulnerabilidades
                    existentes.    
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    g. Cierre de Incidente y Seguimiento:    
                </div>

                <div className="ml-16 mt-5">
                    <b>EL área de Tecnologías e Informática,</b> juntamente con el Representante Legal y las áreas que
                    usan o requieren la información, iniciarán y documentarán todas las tareas de revisión de las
                    acciones que fueron ejecutadas para remediar el incidente de seguridad.    
                </div>

                <div className="ml-16 mt-5">
                    El Representante Legal preparará un <b> análisis anual de los incidentes reportados.</b> Las
                    conclusiones de este informe se utilizarán en la elaboración de campañas de concientización
                    que ayuden a minimizar la probabilidad de incidentes futuros.    
                </div>
                

                <div className="text-xl md:p-10 font-bold pb-0">
                    ATENCIÓN A PETICIONES, CONSULTAS Y RECLAMOS.
                </div>

                <div className="ml-10 mt-5">
                    Las peticiones, consultas y reclamos formulados por los titulares de Datos Personales bajo
                    Tratamiento de <b> FinMaq</b> para ejercer sus derechos a conocer, actualizar, rectificar y suprimir
                    datos, o revocar la autorización deberán ser dirigidas al Representante Legal:
                </div>

                <div className="ml-16 mt-5">
                    - Dirección física: Carrera 5 #117-13 de la ciudad de Bogotá.
                </div>

                <div className="ml-16">
                    - Correo electrónico: <b>datos@fsm.com.co</b>
                </div>

                <div className="ml-16">
                    - Celular 3017106346.
                </div>

                <div className="ml-10 mt-5">
                    Las consultas presentadas deberán contener como mínimo la siguiente información:
                </div>

                <div className="ml-16 mt-5">
                    <b>a.</b> Nombres y apellidos del Titular y/o su representante y/o causahabientes;
                </div>

                <div className="ml-16">
                    <b>b.</b> Lo que se pretende consultar
                </div>

                <div className="ml-16">
                    <b>c.</b> Dirección física, electrónica y teléfono de contacto del Titular y/o sus
                    causahabientes o representantes;
                </div>

                <div className="ml-16">
                    <b>d.</b> Firma, número de identificación o procedimiento de validación correspondiente.
                </div>

                <div className="ml-16">
                    <b>e.</b> Haber sido presentada por los medios de consulta habilitados por <b>FinMaq.</b>
                </div>

                <div className="text-xl md:p-10 font-bold pb-0">
                    PROCEDIMIENTO PARA ATENDER LOS DERECHOS DE LOS TITULARES.
                </div>

                <div className="ml-10 mt-5">
                    Los titulares de Datos Personales, sin importar el tipo de vinculación que tengan con
                    <b> FinMaq,</b> pueden ejercer sus derechos a conocer, actualizar, rectificar y suprimir información
                    y/o revocar la autorización otorgada, de acuerdo con el siguiente Procedimiento:
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    a. PROCEDIMIENTO DE CONSULTAS.
                </div>

                <div className="ml-16 mt-5">
                    <b>FinMaq</b> garantiza a los titulares de datos personales contenidos en sus bases de datos o a
                    sus causahabientes o personas autorizadas, el derecho de consultar toda la información
                    contenida en su registro individual o toda aquella que esté vinculada con su identificación
                    conforme se establece en la presente Política de Tratamiento de Datos Personales.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    Responsable de atención de consultas:
                </div>

                <div className="ml-16 mt-5">
                    El Representante legal de  <b>FinMaq,</b> será el responsable de recibir y dar trámite a las
                    solicitudes remitidas, en los términos, plazos y condiciones establecidos en la Ley 1581 de
                    2012 y en las presentes políticas.
                </div>

                <div className="ml-16 mt-5">
                    Una vez sea recibida la solicitud de CONSULTA de información por parte del Titular de los
                    datos o su representante o tercero debidamente autorizado, a través de los canales
                    establecidos para ello, el Representante Legal procederá a verificar que la solicitud contenga
                    todas las especificaciones requeridas a efectos de poder valorar que el derecho se ejerza por
                    un interesado o por un representante de éste, acreditando con ello, que se cuenta con la
                    legitimidad legal para hacerlo.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    Plazos de Respuesta a consultas:
                </div>

                <div className="ml-16 mt-5">
                    Las solicitudes recibidas mediante los anteriores medios serán atendidas en un término
                    máximo de diez (10) días hábiles contados a partir de la fecha de su recibo.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    Prórroga del plazo de Respuesta:
                </div>

                <div className="ml-16 mt-5">
                    En caso de imposibilidad de atender la consulta dentro de dicho término, se informará al
                    interesado antes del vencimiento de los diez (10) días, expresando los motivos de la demora
                    y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar
                    los cinco (5) días hábiles siguientes al vencimiento del primer plazo.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    b. PROCEDIMIENTO DE RECLAMOS
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    Derechos Garantizados mediante el procedimiento de reclamos:
                </div>

                <div className="ml-28 mt-5">
                    - Corrección o Actualización.
                </div>

                <div className="ml-28">
                    - Revocatoria de la autorización o Supresión de los datos Personales.
                </div>

                <div className="ml-28">
                    - Advertir sobre presunto incumplimiento de la Ley 1581 de 2012 o de la presente
                    Política de tratamiento de datos personales.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    Responsable de atención de Reclamos:
                </div>

                <div className="ml-16 mt-5">
                    El Representante Legal de <b> FinMaq</b> será el responsable de recibir y dar trámite a las
                    solicitudes remitidas, en los términos, plazos y condiciones establecidos en la Ley 1581 de
                    2012 y en las presentes políticas.
                </div>

                <div className="ml-16 mt-5">
                    Las reclamaciones presentadas deberán contener como mínimo la siguiente información:
                </div>

                <div className="ml-28 mt-5">
                    <b>a.</b> Nombres y apellidos del Titular y/o su representante y/o causahabientes;
                </div>

                <div className="ml-28">
                    <b>b.</b> Lo que se pretende consultar
                </div>

                <div className="ml-28">
                    <b>c.</b> Dirección física, electrónica y teléfono de contacto del Titular y/o sus
                    causahabientes o representantes;
                </div>

                <div className="ml-28">
                    <b>d.</b> Firma, número de identificación o procedimiento de validación correspondiente.
                </div>

                <div className="ml-28">
                    <b>e.</b> Haber sido presentada por los medios de consulta habilitados por FinMaq.
                </div>

                <div className="ml-16 mt-5">
                    Una vez sea recibida la solicitud de ACTUALIZACIÓN o de RECTIFICACIÓN de información
                    por parte del Titular de los datos o su representante o tercero debidamente autorizado, a
                    través de los canales establecidos para ello, el representante legal de FinMaq procederá a
                    verificar que la solicitud contenga todas las especificaciones requeridas a efectos de poder
                    valorar que el derecho se ejerza por un interesado o por un representante de éste,
                    acreditando con ello, que se cuenta con la legitimidad legal para hacerlo.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    Reclamaciones sin cumplimiento de Requisitos legales:
                </div>

                <div className="ml-16 mt-5">
                    En caso de que la reclamación se presente sin el cumplimiento de los anteriores requisitos
                    legales, se solicitará al reclamante dentro de los cinco (5) días siguientes a la recepción del
                    reclamo, para que subsane las fallas y presente la información o documentos faltantes.
                    Desistimiento del Reclamo:
                </div>

                <div className="ml-16 mt-5">
                    Transcurrido Un (1) mes desde la fecha del requerimiento sin que el solicitante presente la
                    información requerida, se entenderá que ha desistido del reclamo.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    Plazos de Respuesta a los Reclamos:
                </div>

                <div className="ml-16 mt-5">
                    El término máximo para atender el reclamo será de quince (15) días hábiles contados a
                    partir del día siguiente a la fecha de su recibo.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    Prórroga del plazo de Respuesta:
                </div>

                <div className="ml-16 mt-5">
                    Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al
                    interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en
                    ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer
                    término.
                </div>

                <div className="ml-16 mt-5 font-semibold">
                    c. PROCEDIMIENTO DE SUPRESIÓN DE DATOS PERSONALES.
                </div>

                <div className="ml-16 mt-5">
                    En caso de resultar procedente la Supresión de los datos personales del titular de la base de
                    datos conforme a la reclamación presentada, FinMaq deberán realizar operativamente la
                    supresión de tal manera que la eliminación no permita la recuperación de la información, sin
                    embargo, el Titular deberá tener en cuenta que en algunos casos cierta información deberá
                    permanecer en registros por cumplimiento de deberes legales.
                </div>
                

                <div className="text-xl md:p-10 font-bold pb-0">
                    CAPACITACIÓN DE FUNCIONARIOS Y CONTRATISTAS.
                </div>

                <div className="ml-10 mt-5">
                    <b>FinMaq desarrollará programas anuales de capacitación y concientización</b> en Protección de
                    datos personales y seguridad de la información. <b> FinMaq</b> debe poner en conocimiento estas
                    políticas por el medio que considere adecuado y con ello, capacitar a sus empleados en la
                    administración de los datos personales con una <b> periodicidad al menos anual,</b> con el fin de
                    medir sus conocimientos sobre el particular.
                </div>

                <div className="ml-10 mt-5">
                    En el desarrollo de los programas de capacitación y concientización se deberá asegurar que
                    quienes participen conozcan sus responsabilidades con respecto a Protección de datos
                    personales y seguridad de la información.
                </div>

                <div className="ml-10 mt-5">
                    Los programas de capacitación serán <b> actualizados de forma periódica.</b>
                </div>

                <div className="ml-10 mt-5">
                    <b>Desde el proceso de Gestión de Talento Humano, conjuntamente con el Representante
                    Legal,</b> se definirán los planes de capacitación y evaluación de los empleados de acuerdo con
                    los cambios normativos que se vayan presentando.
                </div>

                <div className="text-xl md:p-10 font-bold pb-0">
                    PROCESOS DE REVISIÓN Y AUDITORÍAS DE CONTROL.
                </div>

                <div className="ml-10 mt-5">
                    <b>FinMaq realizará procesos de revisión o auditorías</b> en materia de protección de datos
                    personales verificando de manera directa o a través de terceros, que las políticas y
                    procedimientos se han implementado adecuadamente en <b>FinMaq.</b>
                </div>

                <div className="ml-10 mt-5">
                    Con base a los resultados obtenidos, se diseñarán e implementarán los planes de
                    mejoramiento (preventivos, correctivos y de mejora) necesarios.
                </div>

                <div className="text-xl md:p-10 font-bold pb-0">
                    PERÍODO DE VIGENCIA DE LAS BASES DE DATOS.
                </div>

                <div className="ml-10 mt-5">
                    Las Bases de Datos de <b> FinMaq</b> tendrán el periodo de vigencia que corresponda a la
                    finalidad para el cual se autorizó su tratamiento y permanecerán en las bases de datos de
                    <b> FinMaq</b> por el término exigido por la ley para la conservación de documentos e información.
                    En todo caso, el Titular podrá revocar la autorización siempre y cuando sea posible legal y
                    contractualmente.
                </div>

                <div className="text-xl md:p-10 font-bold pb-0">
                    VIGENCIA, VERSIONES Y ACTUALIZACIÓN DE LA POLÍTICA.
                </div>

                <div className="ml-10 mt-5">
                    La presente política de tratamiento de datos personales rige a partir de su publicación en la
                    página corporativa www.fsm.com.co y complementa las políticas asociadas, con vigencia
                    indefinida.
                </div>

                <div className="ml-10 mt-5 mb-32">
                    Cualquier cambio sustancial en las políticas de Tratamiento de Datos Personales, se
                    comunicará de forma oportuna a los titulares de los datos a través de del sitio web:
                    <a href="https://www.finmaq.com.co/"><b><u>https://www.finmaq.com.co/</u></b></a>
                </div>
                <hr/>
            </div>
            <Footer/>
        </div>
    )
}